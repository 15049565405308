.main-container{
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Raleway', sans-serif;
    background: linear-gradient(110deg, #fff 47%, #000 45%);
}

.main-text {
    text-align: left;
    margin: 10px;
    font-weight: 600;
    font-size: 2rem;
    color: #fff;
}

.main-subtext {
    text-align: left;
    margin: 10px;
    color: #fff;
}

.main-title {
    text-align: left;
    color: #fff;
    font-size: 1.5rem;
}

@media (min-width:0px){
    .main-container{
        background: #000;
    }

    .image-container{
        display: none;
    }

    .imageAlt-container{
        display: contents;
    }

    .alt-image{
        padding-bottom: 20px;
    }
    
}

@media (min-width:320px){
    .main-container{
        background: #000;
    }

    .image-container{
        display: none;
    }

    .imageAlt-container{
        display: contents;
    }

    .alt-image{
        padding-bottom: 20px;
    }
    
}

@media (min-width:768px){
    .main-container{
        background: linear-gradient(110deg, #fff 47%, #000 45%);
    }

    .image-container{
        display: contents;
    }
    
    .imageAlt-container{
        display: none;
    }

    .main-image{
        margin-top: 10%;
    }

}

@media (min-width:992px){

    .main-text{
        padding-top: 6%;
    }

    .main-image{
        margin-top: 5%;
    }

}

@media (min-width:1200px){
    .main-text{
        padding-top: 8%;
    }

    .main-title{
        font-size: 2rem;
    }

    .main-subtext{
        font-size: 1rem;
    }

}

@media (min-width:1400px){
    .main-text{
        padding-top: 10%;
    }

    .main-title{
        font-size: 2rem;
    }

    .main-subtext{
        font-size: 1rem;
    }

}