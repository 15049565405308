.container-services{
    padding: 25px;
    font-family: 'Raleway', sans-serif;
}

.title-services{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center;
}

.textc-services {
    border: 1px solid #000000;
    width: 100%;
    padding: 5px 10px;
    margin-top: 10px;
    background: white;
    font-size: 0.9rem;
    transition: 0.25s ease;
}

.textc-services.active {
  background-color: #000000;
  color: white;
}

.textc-services:hover {
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    transition: 0.25s ease;
}

.text-services{
    font-size: 1rem;
    margin: 5px;
}

.imagesc-services{
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2);
    transition: 1s ease;
    margin-top: 10px;
    margin-bottom: 10px;
}

.image-services{
    width: 100%;
    cursor: pointer;
    object-fit: cover;
    height: 350px;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .fullscreen-overlay img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }


.infoA-services{
    text-align: left;
}

.infoB-services{
    text-align: left;
    padding-top: 25px;
    padding-bottom: 25px;
}

#x-icon1{
    font-size: 1.5rem;
    color:#ffffff;
    position: fixed;
    top: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 10000; /* Ensure the icon stays on top */
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ffffff;
}

#x-icon1:hover{
    background-color: #000000;
}

.dropbtnS {
    background-color: #ffffff;
    color: #000000;
    border: none;
    margin: 5px;
    padding: 0px;
  }
  
  .dropdownS {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-contentS {
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-contentS a {
    color: black;
    padding: 10px 12px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-contentS a:hover {background-color: #ddd;}
  
  .dropdownS:hover .dropdown-contentS {
    display: block;
  }

@media (min-width:0px) {
}

@media (min-width:320px) {
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}

@media (min-width:1400px) {

}