.mainI-container {
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Raleway', sans-serif;
}

.imageM-container {
    padding-top: 20px;
}

.mainI-image {
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2);
    transition: 1s ease;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
    height: 300px;
}

.mainI-image:hover {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
    transition: 1s ease;
}

.mainI-text {
    text-align: left;
    margin: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 2rem;
}

.mainI-subtext {
    text-align: left;
    margin: 10px;
    font-family: 'Raleway', sans-serif;
}

.mainI-buttons {
    text-align: center;
}

.mainI-button {
    border: 1px solid #000000;
    width: 120px;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    background: white;
    font-size: 0.9rem;
    transition: all 1s;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height:15px;
        position: absolute;
        border: 0px solid #fff;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 3px solid black;
        border-left: 3px solid black;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 3px solid black;
        border-right: 3px solid black;
    }

    &:hover {
        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }

}

.mainI-button:hover {
    background-color: white;
    color: black;
}

@media (min-width:320px) {
    .mainI-text {
        font-size: 2rem;
    }

    .mainI-subtext {
        font-size: 1rem;
    }
}

@media (min-width:768px) {
    .mainI-text {
        padding-top: 5%;
        font-weight: 700;
    }

    .mainI-title {
        font-size: 1.2rem;
    }

    p {
        font-size: .8rem;
    }

    .mainI-subtext {
        font-size: 0.5rem;
    }
}

@media (min-width:992px) {
    .mainI-text {
        padding-top: 10%;
    }

    .mainI-title {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }
}

@media (min-width:1200px) {
    .mainI-text {
        padding-top: 12%;
    }

    .mainI-title {
        font-size: 1.7rem;
    }

    p {
        font-size: 1rem;
    }
}

@media (min-width:1400px) {
    .mainI-text {
        padding-top: 12%;
    }

    .mainI-title {
        font-size: 2rem;
    }

    p {
        font-size: 1rem;
    }
}