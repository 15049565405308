.main-map{
    font-family: 'Raleway', sans-serif;
    padding-top: 40px;
}
.map-title{
    font-size: 1.5rem;
    font-weight: 300;
    color: #000000;
}

.map-texta{
    font-size: 0.9rem;
    color: #000000;
}

.map-text{
    font-size: 0.9rem;
    color: #000000;
    padding-bottom: 3px;
}

.div-map{
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    padding: 5px 10px;
    background: #ffffff;
    font-size: 0.9rem;
    color:#000000;
    
    &:after,
    &:before {
        content: " ";
        width: 50px;
        height: 50px;
        position: absolute;
        border: 0px solid #ffffff;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 10px solid #000000;
        border-left: 10px solid #000000;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 10px solid #000000;
        border-right: 10px solid #000000;
    }

    
}

@media (min-width:0px){
    .map{
        width: 100%;
        height: 400px;
        box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
    }
    .div-map{
        padding-top: 8%;
        padding-left: 7%;
        padding-bottom: 10%;
        padding-right: 7%;
        text-align: center;
    }
    .map-texta{
        font-size: 0.8rem;
    }
}

@media (min-width:320px){
    .map-texta{
        padding-left: 2%;
    }
}

@media (min-width:768px){
    .map-title{
        padding: 5%;
    }
    .map-texta{
        font-size: 0.8rem;
    }
    .map-texta{
        font-size: 0.8rem;
    }
    .div-map{
        height: 400px;
    }
}

@media (min-width:992px){
    .map-texta{
        font-size: 1rem;
    }
    .map-text{
        font-size: 1rem;
    } 
    .map-title{
        padding-top: 5%;
    }
}

@media (min-width:1200px){
    .map-title{
        padding-top: 3%;
    }
}

@media (min-width:1400px){
    .map-title{
        padding-top: 1%;
    }
}