.us-container{
    padding: 25px;
    font-family: 'Raleway', sans-serif;
}

.us-title{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 25px;
}

.us-text{
    text-align: left;
    font-size: 1rem;
}

.usc-textl{
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 40px;
    height: 225px;
    font-size: 0.9rem;
    transition: all 1s;
    background-color: #ffffff;
    color: #000000;
    margin: 25px 0px;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height:15px;
        position: absolute;
        border: 0px solid #000000;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 3px solid #000000;
        border-left: 3px solid #000000;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 3px solid #000000;
        border-right: 3px solid #000000;
    }

    &:hover {

        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }
}

.usc-textr{
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 40px;
    height: 225px;
    font-size: 0.9rem;
    transition: all 1s;
    background-color: #ffffff;
    color: #000000;
    margin: 25px 0px;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height:15px;
        position: absolute;
        border: 0px solid #000000;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        right: 0px;
        border-top: 3px solid #000000;
        border-right: 3px solid #000000;
    }

    &:before {
        bottom: 0px;
        left: 0px;
        border-bottom: 3px solid #000000;
        border-left: 3px solid #000000;
    }

    &:hover {

        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }
}

.usI-container{
    margin: 20px;
}

.usI-title{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0px;
}

.usI-text{
    font-size: 1rem;
    margin-bottom: 0px;
}

.us-imagec{
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    margin-bottom: 10px;
}

.us-image{
    width: 100%;
    object-fit: cover;
    height: 400px;
}

@media (min-width:0px) {
}

@media (min-width:320px) {}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}

@media (min-width:1400px) {}