.service-container {
    padding-top: 20px;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 20px;
}

.services-right {
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 20px;
    background: white;
    font-size: 0.9rem;
    transition: all 1s;
    color: #000000;
    margin-bottom: 2px;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height: 15px;
        position: absolute;
        border: 0px solid #fff;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        right: 0px;
        border-top: 3px solid black;
        border-right: 3px solid black;
    }

    &:before {
        bottom: 0px;
        left: 0px;
        border-bottom: 3px solid black;
        border-left: 3px solid black;
    }

    &:hover {

        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }
}

.services-left {
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 20px;
    font-size: 0.9rem;
    transition: all 1s;
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 2px;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height: 15px;
        position: absolute;
        border: 0px solid #000000;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 3px solid #000000;
        border-left: 3px solid #000000;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 3px solid #000000;
        border-right: 3px solid #000000;
    }

    &:hover {

        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }
}

.services-rightd {
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 20px;
    background: white;
    font-size: 0.9rem;
    transition: all 1s;
    color: #000000;
    margin-bottom: 2px;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height: 15px;
        position: absolute;
        border: 0px solid #fff;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 3px solid black;
        border-left: 3px solid black;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 3px solid black;
        border-right: 3px solid black;
    }

    &:hover {

        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }
}

.services-leftd {
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 20px;
    font-size: 0.9rem;
    transition: all 1s;
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 2px;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height: 15px;
        position: absolute;
        border: 0px solid #000000;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        right: 0px;
        border-top: 3px solid #000000;
        border-right: 3px solid #000000;
    }

    &:before {
        bottom: 0px;
        left: 0px;
        border-bottom: 3px solid #000000;
        border-left: 3px solid #000000;
    }

    &:hover {

        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }
}

.services-title {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 20px;
    margin: 0px;
}

.services-subtitle {
    text-align: left;
    font-size: 1.2rem;
}

.services-subtext {
    text-align: left;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.services-link {
    color: #000000;
    text-decoration: none; 
}

.services-link:hover {
    color: #000000;
    animation: none;
}

@media (min-width:320px) {}

@media (min-width:768px) {
    .services-left {
        width: 104%;
    }

    .services-right {
        margin-left: -3%;
        width: 103%;
    }

    .services-leftd {
        width: 104%;
    }

    .services-rightd {
        margin-left: -3%;
        width: 103%;
    }
}

@media (min-width:992px) {
    .services-left {
        width: 103%;
    }

    .services-right {
        margin-left: -2%;
        width: 102%;
    }

    .services-leftd {
        width: 103%;
    }

    .services-rightd {
        margin-left: -2%;
        width: 102%;
    }
}

@media (min-width:1200px) {
    .services-left {
        width: 102%;
    }

    .services-right {
        width: 101%;
    }

    .services-leftd {
        width: 102%;
    }

    .services-rightd {
        width: 101%;
    }
}

@media (min-width:1400px) {
    .services-left {
        width: 101%;
    }

    .services-right {
        width: 101%;
    }

    .services-leftd {
        width: 101%;
    }

    .services-rightd {
        width: 101%;
    }
}