.slider-container{
    padding-top: 20px;
    font-family: 'Raleway', sans-serif;
    padding-bottom: 40px;
}

.slider-title{
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 20px;
    margin: 0px;
    text-align: center;
}

.slider-images{
    width: 100%;
    cursor: pointer;
    object-fit: cover;
    height: 300px;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2);
}

.slider-titlec{
    margin: auto;
    padding-bottom: 20px;
}

.slider-text{
    text-align: left;
}

.carousel-control-next{
    opacity: 1;
    transition: all .5s;
}

.carousel-control-prev{
    opacity: 1;
    transition: all .5s;
}

.carousel-control-next:hover{
    background-color: rgba(0, 0, 0, 0.3);
    transition: all .5s;
}

.carousel-control-prev:hover{
    background-color: rgba(0, 0, 0, 0.3);
    transition: all .5s;
}

.slider-button {
    border: 1px solid #000000;
    width: 120px;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    background: white;
    font-size: 0.9rem;
    transition: all 1s;

    &:after,
    &:before {
        content: " ";
        width: 15px;
        height:15px;
        position: absolute;
        border: 0px solid #fff;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 3px solid black;
        border-left: 3px solid black;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 3px solid black;
        border-right: 3px solid black;
    }

    &:hover {
        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }

}

@media (min-width:0px){
    .slider-text{
        font-size: 1rem;
    }
}

@media (min-width:320px){

}

@media (min-width:768px){

}

@media (min-width:992px){

}

@media (min-width:1200px){

}

@media (min-width:1400px){

}