.header {
    background-color: white;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.2);
    font-family: 'Raleway', sans-serif;
    /*border-bottom: 1px solid #000;*/
}

.burger{
    border-radius: 0px;
    color: #000000;
    border: 0px;
    font-size: 1.5rem;
}

.burger:hover{
    color: #ffffff;
    background-color: #000000;
}

.header-text{
    color: #000000;
    text-align: center;
    text-decoration: none;
    margin: 5px;
}

.dropbtn {
    background-color: #ffffff;
    color: #000000;
    border: none;
    margin: 5px;
    padding: 0px;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 10px 12px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

@media (min-width:0px){
   
}

@media (min-width:320px){
    
}

@media (min-width:768px){

}

@media (min-width:992px){
  .dropdown-content {
    position: absolute;
  }
}

@media (min-width:1200px){

}

@media (min-width:1400px){

}