.gallery-container{
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Raleway', sans-serif;
}

.gallery-grid {
    display: grid;
    grid-gap: 10px;
}

.gallery-container {
    padding: 20px;
}

.galleryI-container {
    position: relative;
    padding-top: 20px;
    margin: auto;
}

.carousel-thumbnails {
    margin-top: 10px;
    overflow-x: auto;
    white-space: nowrap;
}

.thumbnail-col {
    padding: 5px;
    display: inline-block;
}

.thumbnail {
    width: 100%;
    cursor: pointer;
    opacity: 0.6;
    object-fit: cover;
}

.thumbnail.active,
.thumbnail:hover {
    opacity: 1;
    border: 2px solid #007bff;
}

.gallery-images {
    width: 100%;
    object-fit: cover;
}

.gallery-title{
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0px;
}

.gallery-text{
    font-size: 1.2rem;
}

.gallery-textc{
    margin: auto;
    position: relative;
    padding: 5px 10px;
    background: #ffffff;
    font-size: 0.9rem;
    color:#000000;
    
    &:after,
    &:before {
        content: " ";
        width: 45px;
        height: 45px;
        position: absolute;
        border: 0px solid #ffffff;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 5px solid #000000;
        border-left: 5px solid #000000;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 5px solid #000000;
        border-right: 5px solid #000000;
    }

    
}

.fullscreen-overlayI {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreen-overlayI img {
    max-width: 95%;
    max-height: 95%;
}

.disable-scroll {
    overflow: hidden;
}

#x-icon1 {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

@media (min-width:0px) {
    .gallery-textc{
        width: 100%;
    }
    .gallery-images {
        height: 350px;
    }
    .thumbnail {
        height: 100px;
    }
    .galleryI-container {
        width: 100%;
    }
}

@media (min-width:320px) {
    .gallery-desctext{
        font-size: 1rem;
    }
}

@media (min-width:768px) {
    .gallery-textc{
        width: 60%;
    }
    .gallery-images {
        height: 450px;
    }
    .thumbnail {
        height: 150px;
    }
}

@media (min-width:992px) {
    .gallery-textc{
        width: 40%;
    }
    .gallery-images {
        height: 500px;
    }
    .thumbnail {
        height: 150px;
    }
    .galleryI-container {
        width: 85%;
    }
}

@media (min-width:1200px) {
    .gallery-textc{
        width: 35%;
    }
    .gallery-images {
        height: 600px;
    }
}

@media (min-width:1400px) {}