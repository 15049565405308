.main-footer{
    background-color: #000000;
    color: #ffffff;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Raleway', sans-serif;
}

.footer-menu{
margin:3px;
}

a{
    color: #ffffff;
}

a:hover{
    color: lightgray;
    cursor: pointer;
}

.imagef-container{
    margin: auto;
}

.footer-image{
    text-align: center;
    display: block;
}

.footer-row{
    border-top: 2px solid #ffffff;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

@media (min-width:0px){
    .footer-image{
        width: 100px;
    }
    .footer-text{
        text-align: left;
        font-size: 0.7rem;
        margin: 3px;
        font-weight: 300;
    }
    .footer-button{
        width: 100px;
        padding: 3px 8px;
        font-size: 0.7rem;
    }
    .footer-icon{
        text-align: left;
        font-size: 1rem;
        margin-bottom: 5px;
    }
    .footer-menu{
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
    }
}

@media (min-width:320px){
    
}

@media (min-width:768px){
    .footer-image{
        width: 150px;
    }
    .footer-text{
        text-align: left;
        font-size: 1rem;
        padding-left: 25px;
    }
    .footer-button{
        width: 150px;
        padding: 5px 10px;
        font-size: 1rem;
    }
    .footer-icon{
        text-align: left;
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
    .footer-menu{
        text-align: left;
        font-size: 1.2rem;
        font-weight: 600;
    }
}

@media (min-width:992px){

}

@media (min-width:1200px){

}

@media (min-width:1400px){

}