.main-contact{
    text-align: left;
    margin: 10px 10px;
    font-family: 'Raleway', sans-serif;
    background-color: #000000;
}

.contact-title{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
}

.main-button {
    border: 1px solid #ffffff;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    background: #000000;
    font-size: 0.9rem;
    transition: all 1s;
    color:#ffffff;
    
    &:after,
    &:before {
        content: " ";
        width: 15px;
        height:15px;
        position: absolute;
        border: 0px solid #000000;
        transition: all 1s;
    }

    &:after {
        top: 0px;
        left: 0px;
        border-top: 3px solid #ffffff;
        border-left: 3px solid #ffffff;
    }

    &:before {
        bottom: 0px;
        right: 0px;
        border-bottom: 3px solid #ffffff;
        border-right: 3px solid #ffffff;
    }

    &:hover {
        &:before,
        &:after {

            width: 100%;
            height: 100%;
        }
    }

}

.contact-col{
    position: relative;
    padding: 20px;
    background: #000000;
    font-size: 0.9rem;
}

@media (min-width:0px){
    .contact-form{
        color: #ffffff;
        background-color: #000000;
    }
    
    .contact-input{
        width: 100%;
        font-family:Arial, Helvetica, sans-serif;
        font-size: 0.8rem;
    }
    .contact-text{
        text-align: left;
        font-size: 0.8rem;
    }
    .contact-label{
        text-align: left;
        font-size: 0.9rem;
    }
    .contact-subtext{
        text-align: center;
        font-size: 1rem;
    }
    .contact-label{
        padding-top: 10px;
        padding-bottom: 5px;
    }
}

@media (min-width:320px){
    
}

@media (min-width:768px){
    .contact-form{
        margin: 25px;
    }
    .contact-subtext{
        font-size: 1.2rem;
    }
    .contact-text{
        font-size: 0.8rem;
    }
    .contact-label{
        font-size: 1rem;
    }
    .contact-input{
        font-size: 1rem;
    }
}

@media (min-width:992px){
    .contact-subtext{
        font-size: 1.2rem;
    }
    .contact-label{
        font-size: 1rem;
    }
    .contact-col{
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media (min-width:1200px){
    .contact-subtext{
        font-size: 1.4rem;
    }
}

@media (min-width:1400px){
    .contact-subtext{
        font-size: 1.6rem;
    }
}
